<template>
	<div class="_pc-pad-y-30 _pc-pad-x-310" style="background-color: #f5f5f5;">
		<div class="user-index">
			<div class="user-info-box">
				<div class="left">
					<div class="left-head">
						<div class="img-box" style="border: 1px solid #999;">
							<img style="width: 100%;height: auto;" :src="logo" alt="" v-if="Islogin">
							<img style="width: 100%;height: auto;" src="~@/assets/image/hangda/shucai/sucaiavatar.png" alt="" v-else>
						</div>
						<div class="user-name-box">
							<div class="_b _pc-font-21" style="line-height:1;">{{ userInfo.nickname }}</div>
							<div class="_pc-font-15 _pc-mar-top-10" style="color: #999;">{{ userInfo.email }}</div>
						</div>
					</div>
					
				</div>
				<div class="right">
					<div class="grid-box-nav _grey-99">
						<div class="_flex-row-center-mid _white-bg" @click="editUserInfo(true)" style="cursor:pointer;text-align: center;border-radius:9px;">
							<div class="_flex-col-mid">
								<img style="width: 32px;height: 32px;" src="~@/assets/image/hangda/user_edit.png" alt="">
								<div class="_pc-mar-top-10" style="line-height:1;">{{ $t('更改用户名') }}</div>
							</div>
						</div>
						<div class="_flex-row-center-mid _white-bg" @click="editPassword(true)" style="cursor:pointer;text-align: center;border-radius:9px;">
							<div class="_flex-col-mid">
								<img style="width: 32px;height: 32px;" src="~@/assets/image/hangda/user_lock.png" alt="">
								<div class="_pc-mar-top-10" style="line-height:1;">{{ $t('更改密码') }}</div>
							</div>
						</div>
						<div class="_flex-row-center-mid _white-bg" @click="packForecast(true)" style="cursor:pointer;text-align: center;border-radius:9px;">
							<div class="_flex-col-mid">
								<img style="width: 32px;height: 32px;" src="~@/assets/image/hangda/user_baoguoyvbao.png" alt="">
								<div class="_pc-mar-top-10" style="line-height:1;">{{ $t('包裹预报') }}</div>
							</div>
						</div>
						<router-link :to="'/my_report'" class="_flex-row-center-mid _white-bg" style="cursor:pointer;text-align: center;border-radius:9px;">
							<div class="_flex-col-mid">
								<img style="width: 32px;height: 32px;" src="~@/assets/image/hangda/logo/user-apply-pack.png" alt="">
								<div class="_pc-mar-top-10" style="line-height:1;">{{ $t('我的包裹') }}</div>
							</div>
						</router-link>
						<router-link :to="'/list'" v-if="agentInfo.is_main" class="_flex-row-center-mid _white-bg" style="cursor:pointer;text-align: center;border-radius:9px;color:unset;">
							<div class="_flex-col-mid">
								<img style="width: 32px;height: 32px;" src="~@/assets/image/hangda/logo/user-address.png" alt="">
								<div class="_pc-mar-top-10" style="line-height:1;">{{ $t('申请打包') }}</div>
							</div>
						</router-link>
						<div class="_flex-row-center-mid _white-bg" @click="showAddress" style="cursor:pointer;text-align: center;border-radius:9px;">
							<div class="_flex-col-mid">
								<img style="width: 32px;height: 32px;" src="~@/assets/image/hangda/logo/user-apply-pack.png" alt="">
								<div class="_pc-mar-top-10" style="line-height:1;">{{ $t('地址管理') }}</div>
							</div>
						</div>
					</div>
					<!-- <div class="title">{{ $t('user_center.youji_dizhi') }}</div>
					<div class="main">
						<div class="li">
							<div class="content">
								<div class="sort-title">{{ $t('recipient') }}</div>
								<div>{{ userInfo.nickname }}</div>
							</div>
							<div class="content">
								<span class="sort-title">{{ $t('联系电话') }}</span>
								<span>{{ userInfo.phone }}</span>
							</div>
						</div>
						<div class="li">
							<div class="content">
								<span class="sort-title">{{ $t('country') }}/{{ $t('region') }}</span>
								<span>{{ userInfo.area_name }}</span>
							</div>
							<div class="content">
								<span class="sort-title">{{ $t('city') }}</span>
								<span>{{ userInfo.province_name }}</span>
							</div>
						</div>
						<div class="li">
							<span class="sort-title">{{ $t('user_center.detail_address') }}</span>
							<span>{{ userInfo.addres }}</span>
						</div>
					</div> -->
				</div>
			</div>
			<div class="my-order _pc-mar-top-20">
				<!-- <div style="width: 100%;display: flex;">
					<div class="list-type" :class="list_type==1?'list-status':''" @click="selectListType(1)">{{ $t('我的订单') }}</div>
					<div class="list-type" :class="list_type==2?'list-status':''" @click="selectListType(2)">{{ $t('我的包裹') }}</div>
				</div> -->
				<span v-if="list_type==2">
					<div style="width: 100%;height: 20px;"></div>
					<reportList class="main-box-right" style="width: 100%;"></reportList>
				</span>
				<span v-if="list_type==1">
					<div class="my-order-head">
						<div class="_pc-font-26 _b">{{ $t('我的订单') }}</div>
						<div class="search">
							<div class="search-left">
								<div class="iconfont2 icon-sousuo1"></div>
								<input type="text" :placeholder="$t('搜索订单关键词')" v-model="thisFrom.keywords">
							</div>
							<div class="_pc-pad-x-15" style="color: #4284DC;border-left: 1px solid #999;font-size: 18px;cursor:pointer;" @click="search">{{ $t('search') }}</div>
						</div>
					</div>
					<div class="order-status">
						<div class="item" :class="{'item-select':status==0}" @click="changeNav(0)">{{ $t('所有订单') }}</div>
						<div class="item" v-for="(item,index) in orderstatus" :key="index" :class="{'item-select':item.status==status}" @click="changeNav(item.status)">{{item.name}}</div>
					</div>
					<div class="order-list">
						<!-- 订单列表 -->
						<div class="item" v-for="(item,index) in order_list" :key="index"  >
							<div class="item-head" v-if="order_list.length > 0 && !loading">
								<div class="_flex-row-mid">
									<div>{{ $t('订单号') }}：{{ item.ordersn }}</div>
									<div class="_pc-mar-left-80">{{ $t('创建时间') }}：{{ item.add_time	 }}</div>
								</div>
								<div :style="'color:'+item.status_color">
									{{ item.status_name }}
								</div>
							</div>
							<!-- <div class="item-body">
								<div class="_flex-col _pc-w-290 li-1">
									<div class="_flex-row _pc-pad-left-25 pack-num " v-for="(items,index) in item.out_package_list" :key="index">
										<img style="width: 20px;height: 20px;background: #f5f5f5;margin-right: 10px;" src="http://api.kmaonan.com/uploads/attach/2023/06/20230616/d7c6353da508977b456215aaa277b43c.jpg" alt="">
										<span style="word-break: break-all;">{{ $t('打包单号') }}：{{ items.pack_no }}</span>
									</div>
								</div>
								<div class="line"></div>
								<div class="_flex-col-center-mid _pc-w-330 li-2">
									<div>{{ $t('总重量') }} </div>
									<div style="margin-top: 13px;color: #4284DC;">{{item.weight}}kg({{$t('common')}}{{item.report_num}}{{$t('package')}})</div>
								</div>
								<div class="line"></div>
								<div v-if="lang == 'zh_cn'" class="_flex-col-center-mid _pc-w-350 li-3" v-for="(items,index) in item.express_name" :key="index">
									<div>{{ $t('路线') }} </div>
									<div style="margin-top: 13px;color: #4284DC;">{{ items }}</div>
								</div>
								<div v-if="lang == 'ru'" class="_flex-col-center-mid _pc-w-350 li-3" v-for="(items,index) in item.express_name_ru" :key="index">
									<div>{{ $t('路线') }} </div>
									<div style="margin-top: 13px;color: #4284DC;">{{ items }}</div>
								</div>
								<div v-if="lang == 'en_us'" class="_flex-col-center-mid _pc-w-350 li-3" v-for="(items,index) in item.express_name_us" :key="index">
									<div>{{ $t('路线') }} </div>
									<div style="margin-top: 13px;color: #4284DC;">{{ items }}</div>
								</div>
								<div class="line"></div>
								<div class="_flex-col-center-mid _flex-1 li-4">
									<div class="_pc-pad-x-15 _pc-pad-y-10 search-btn" @click="logistics" style="display: none;">{{ $t('user_center.seach_btn') }} </div>
									<div style="display:flex;align-items:center;">
										<Badge :count="item.user_weidu_msg" class="_pc-mar-right-15">
											<div class="_pc-pad-x-15 _pc-pad-y-10 search-btn" @click="getMessage(item.id)" style="margin-bottom:0px;">{{ $t('user_center.message') }}</div>
										</Badge>
										<div class="_pc-font-18 detail" style="display:flex;align-items:center;" @click="orderDetail(item.id)">{{ $t('user_center.more_btn') }}</div>
									</div>
								</div>
							</div> -->
							<div class="item-main">
								<div class="col">
									<div class="ordersn-lis">
										<div class="head">
											<span style="margin-top: 0.2rem;">{{ $t('打包单号') }}：</span>
										</div>
										<div class="num-item">
											<div class="_flex-row-mid" v-for="(items,index) in item.out_package_list" :key="index">
												<img style="width: 1.25rem;height: 1.25rem;background: #f5f5f5;margin-right: 10px;" src="http://api.kmaonan.com/uploads/attach/2023/06/20230616/d7c6353da508977b456215aaa277b43c.jpg" alt="">
												<div style="margin-top: 0.2rem;flex: 1;width: 0;">
													<div style="width: 100%;word-wrap: break-word;">
														{{ items.pack_no }}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col">
									<div>{{ $t('总重量') }} </div>
									<div style="color: #4284DC;" v-if="item.is_type===1">{{item.big_weight}}kg({{$t('common')}}{{item.report_num}}{{$t('package')}})</div>
									<div style="color: #4284DC;" v-else>{{item.weight}}kg({{$t('common')}}{{item.report_num}}{{$t('package')}})</div>
								</div>
								<div class="col">
									<div v-if="lang == 'zh_cn'" class="_flex-col-center-mid li-3" v-for="(items,index) in item.express_name" :key="index">
										<div>{{ $t('路线') }} </div>
										<div style="color: #4284DC;">{{ items }}</div>
									</div>
									<div v-else-if="lang == 'ru'" class="_flex-col-center-mid _pc-w-350 li-3" v-for="(items,index) in item.express_name_ru" :key="index">
										<div>{{ $t('路线') }} </div>
										<div style="color: #4284DC;">{{ items }}</div>
									</div>
									<div v-else-if="lang == 'en_us'" class="_flex-col-center-mid _pc-w-350 li-3" v-for="(items,index) in item.express_name_us" :key="index">
										<div>{{ $t('路线') }} </div>
										<div style="color: #4284DC;">{{ items }}</div>
									</div>
								</div>
								<div class="col action">
									<div class="_pc-pad-x-15 _pc-pad-y-10 search-btn btn" @click="logistics" style="display: none;">{{ $t('user_center.seach_btn') }} </div>
									<Badge :count="item.user_weidu_msg" class="btn">
										<div class="search-btn" @click="getMessage(item.id)" style="margin-bottom:0px;">{{ $t('user_center.message') }}</div>
									</Badge>
									<div class="_pc-font-18 detail " style="display:flex;align-items:center;" @click="orderDetail(item.id)">{{ $t('user_center.more_btn') }}</div>
								</div>
							</div>
						</div>
						<div class="item" style="text-align: center;line-height: 100px;font-size: 18;font-weight: bold;" v-if="order_list.length <= 0 && !loading">~{{$t('暂无数据')}}~</div>
						<div class="acea-row row-right page" style="margin-bottom: 30px;float: right;">
							<Page :total="total" show-elevator show-total @on-change="pageChange" :page-size="thisFrom.limit"/>
						</div>
					</div>
				</span>
			</div>
		</div>
		<div class="mark"  v-if="IsShowEditUserInfo">
			<editUserInfo ref="editUserInfo" @IsShow="editUserInfo" ></editUserInfo>
		</div>
		<div class="mark"  v-if="IsShowEditPassword">
			<editPassword ref="editPassword" @IsShow="editPassword"></editPassword>
		</div>
		<Message ref="orderMessage" @onlound='onlound'></Message>


		<div class="mark"  v-if="IsShowEditAddress">
			<editAddress ref="editAddress" @IsShow="editAddress" ></editAddress>
		</div>
		
		<address-list :IsShow="show_address_list" @close="addressClose()"></address-list>
	</div>
</template>

<script>
	import editPassword from './components/editPassword';
	import editUserInfo from './components/editUserInfo';
	import editAddress from './components/editAddress';
	import addressList from './components/address_list';
	import { getCookies } from "@/libs/util.js"
	import { getUserInfo, userOrderList, bannerList } from '@/api/jiyun.js'
	import reportList from '../index/components/reportList';
	import Message from './components/message';
	export default {
		name: 'user',
		components: {
			editPassword,
			editUserInfo,
			reportList,
			Message,
			editAddress,
			addressList,
		},
		data() {
			return {
				metaInfo:{
					title: this.$t('page_title.user'),
					keyWords:'',
					description:''
				},
				orderstatus:[
					{
						status:80,
						name:this.$t('已打包')
					},
					{
						status:85,
						name:this.$t('已合包')
					},
					{
						status:190,
						name:this.$t('已发货')
					},
					{
						status:100,
						name:this.$t('国外签收')
					},
					{
						status:110,
						name:this.$t('国外上架')
					},
					{
						status:120,
						name:this.$t('已完成')
					}
				],
				status:0,
				total: 0,
				IsShowEditPassword:false,
				IsShowEditUserInfo:false,
				IsShowEditAddress:false,
				show_address_list:false,
				userInfo: {},
				Islogin: false,
				order_list: [],
				thisFrom: {
					page: 1,
					limit: 10,
					keywords: '',
					domain_url: window.location.origin
				},
				loading: false,
				list_type: 1,
				logo: '',
				agentInfo: {},
				lang: '',
			}
		},
		metaInfo() {
			return {
				title: this.metaInfo.title
			}
		},
		created(){
			this.lang = localStorage.getItem('think-lang') || 'ru';
			let token = getCookies('web-token');
			if(token){
				this.getUserInfo();
				this.getList();
				this.bannerList();
			}else{
				this.$Message.warning(this.$t('请先登录'));
				// this.$router.go(-1);
				this.$router.push('/login');
			}
		},
		methods: {
			// 代理banner
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agentInfo = res.data;
					this.logo = res.data.logo;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 切换列表
			selectListType(index){
				this.list_type = index;
			},
			packForecast(){
				this.$router.push({
					path: '/report'
				})
			},
			// 物流查询
			logistics(){
				this.$router.push({
					name: 'index-search',
				})
			},
			// 订单详情
			orderDetail(orderId){
				this.$router.push({
					path: '/order_detail',
					query: {
						orderId: orderId
					}
				})
			},
			// 订单列表
			getList(){
				this.loading = true;
				userOrderList(this.thisFrom).then((res)=>{
					this.loading = false;
					this.order_list = res.data.list;
					this.total = res.data.count;
				}).catch((err)=>{
					this.loading = false;
					this.$Message.error(err.msg)
				})
			},
			// 获取用户信息
			getUserInfo(){
				getUserInfo().then((res)=>{
					this.userInfo = res.data;
					this.Islogin = true;
				}).catch((err)=>{
					this.$Message.error(err.msg)
					this.Islogin = false;
				})
			},
			changeNav(index) {
				this.status = index;
				this.thisFrom.status = index;
				this.getList();
			},
			// 修改密码
			editPassword(show) {
				if(show){
					this.IsShowEditPassword = show;
				}else{
					this.IsShowEditPassword = show;
					this.getUserInfo();
				}
			},
			// 修改用户信息
			editUserInfo(show) {
				if(show){
					this.IsShowEditUserInfo = show;
					this.$nextTick((e)=>{
						this.$refs.editUserInfo.getUserInfo()
					})
				} else {
					this.IsShowEditUserInfo = show;
					this.getUserInfo();
				}
			},

			// 修改用户信息
			editAddress(show) {
				if(show){
					this.IsShowEditAddress = show;
					this.$nextTick((e)=>{
						this.$refs.editAddress.getUserInfo()
					})
				} else {
					this.IsShowEditAddress = show;
					this.getUserInfo();
				}
			},
			// 搜索
			search(){
				this.thisFrom.page = 1;
				this.getList();
			},
			// 分页
			pageChange(index) {
				this.thisFrom.page = index
				this.getList()
			},

			//打开留言
			getMessage(id){
				this.$refs.orderMessage.getMessage(id);
			},

			//关闭留言弹窗重新获取订单列表
			onlound(){
				this.getList();
			},


			showAddress(){
				if(false){
					this.IsShowEditAddress = true;
				}else{
					this.show_address_list = true;
				}
			},

			addressClose() {
				this.show_address_list = false;
			}
		}
	}
</script>

<style scoped>
	.item-main {
		display: grid;
		grid-template-columns: 30% 1fr 1fr auto;
	}
	.item-main .col {
		position: relative;
		padding: 2rem 2rem;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.item-main .col::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 3rem;
		left: 0;
		right: 0;
		border-right: 1px solid #ebebeb;
	}
	.item-main .col:last-child::after {
		display: none;
	}
	.item-main .col .ordersn-lis {
		width: 100%;
	}
	.item-main .action {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		column-gap: 0.375rem;
	}
	.item-main .action>.btn {
		flex-shrink: 0;
	}
	.ordersn-lis .head {
		width: 100%;
		display: flex;
		align-items: center;
	}
	.mark {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background: rgba(0, 0, 0, 0.4);
		z-index: 999;
	}
	.user-index {
		color: #333333;
		font-size: 18px;
		width: 100%;
	}
	.user-index .user-info-box {
		/* display: flex; */
		display: grid;
		grid-template-columns: 1fr 60%;
		grid-column-gap: 22px;
	}
	.user-index .user-info-box .left {
		border-radius: 10px;
		background: #ffffff;
		padding: 30px 20px;
		/* width: 580px; */
		width: 100%;
		margin-right: 22px;
		flex-shrink: 0;
	}
	.user-index .user-info-box .left .left-head {
		display: flex;
		align-items: center;
	}
	.user-index .user-info-box .left .left-head .img-box {
		width: 55px;
		height: 55px;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 15px;
	}
	.user-index .user-info-box .left .left-head .user-name-box {
		display: flex;
		flex-direction: column;
	}
	.user-index .user-info-box .left .left-bottom {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 10px;
	}
	.user-index .user-info-box .left .left-bottom .li {
		width: 100%;
		padding: 15px 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #999;
		background-color: #f7f7f7;
		border-radius: 9px;
	}
	.user-index .user-info-box .right {
		flex: 1;
		/* border-radius: 10px;
		background-color: #ffffff;
		padding: 0px 33px;
		padding-top: 30px; */
	}
	.user-index .user-info-box .right .title {
		font-size: 22px;
		color: #333333;
		font-weight: bold;
	}
	.user-index .user-info-box .right .main {
		width: 100%;
		font-size: 18px;
	}
	.user-index .user-info-box .right .main .li {
		width: 100%;
		padding: 20px 0px;
		border-bottom: 1px solid rgba(153, 153, 153, 0.2);
		display: grid;
		grid-template-columns: 50% 50%;
		grid-column-gap: 10px;
		word-break: break-word;
	}
	.user-index .user-info-box .right .main .li .content {
		display: flex;
	}
	.user-index .user-info-box .right .main .li .content .sort-title {
		flex-shrink: 0;
	}
	.sort-title {color: #999;margin-right: 50px;}
	.user-index .user-info-box .right .main .li:last-child {
		border-bottom: none;
	}
	.user-index .my-order {
		width: 100%;
		border-radius: 10px;
		background-color: #ffffff;
		overflow: hidden;
	}
	.user-index .my-order .my-order-head {
		width: 100%;
		height: 108px;
		border-bottom: 1px solid #d8d8d8;
		padding: 0px 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.user-index .my-order .my-order-head .search {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 9px;
		background: #f5f5f5;
		padding: 0px 16px;
	}
	.user-index .my-order .my-order-head .search .search-left {
		display: flex;
		align-items: center;
	}
	.user-index .my-order .my-order-head .search .search-left .icon-sousuo1 {
		transform: rotateY(180deg);
		color: #383838;
		font-size: 24px;
		margin-right: 12px;
	}
	.user-index .my-order .my-order-head .search .search-left input {
		width: 450px;
		height: 52px;
		outline: none;
		background-color: #f5f5f5;
	}
	.user-index .my-order .order-status {
		width: 100%;
		height: 105px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.user-index .my-order .order-status .item {
		width: calc(100% / 6);
		height: 100%;
		color: #333333;
		font-size: 16px;
		line-height: 105px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		cursor: pointer;
	}
/* 	.user-index .my-order .order-status .item:hover::after {
		content: '';
		position: absolute;
		bottom: 20px;
		width: 30px;
		height: 4px;
		border-radius: 4px;
		background-color: #4284dc;
	} */
	.user-index .my-order .order-status .item-select {
		width: calc(100% / 6);
		height: 100%;
		color: #4284dc;
		position: relative;
	}
	.user-index .my-order .order-status .item-select::after {
		content: '';
		position: absolute;
		bottom: 20px;
		width: 30px;
		height: 4px;
		border-radius: 4px;
		background-color: #4284dc;
	}
	.user-index .my-order .order-list {
		width: 100%;
		margin-top: 20px;
		padding: 0px 30px;
		overflow: scroll;
		overflow-x: hidden;
		padding-bottom: 35px;
		/* background-color: #d9fffb; */
	}
	.user-index .my-order .order-list .item {
		width: 100%;
		border: 1px solid #d9d9d9;
		margin-bottom: 25px;
	}
	.user-index .my-order .order-list .item .item-head {
		width: 100%;
		height: 57px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0px 23px;
		background: #f5f5f5;
		border-bottom: 1px solid #d9d9d9;
	}
	.user-index .my-order .order-list .item .item-body {
		padding: 25px 0px;
		display: flex;
		align-items: center;
	}
	.user-index .my-order .order-list .item .item-body .li-1 .pack-num{
		margin-bottom: 20px;
	}
	.user-index .my-order .order-list .item .item-body .li-1 .pack-num:last-child {
		margin-bottom: 0px;
	}
	.detail {
		font-size: 1rem;
		border-radius: 8px;
		border: 1px solid #4284DC;
		color: #4284DC;
		cursor: pointer;
		padding: 0.475rem 0.75rem;
	}
	.search-btn {
		border-radius: 8px;
		color: #ffffff;
		padding: 0.475rem 0.75rem;
		background-color: #4284DC;
		font-size: 1rem;
		cursor: pointer;
	}
	.user-index .my-order .order-list .item .item-body .line {
		width: 1px;
		height: 43px;
		border: 1px solid #999999;
		opacity: 0.3;
	}
	.list-type {
		width: 50%;
		font-size: 28px;
		text-align: center;
		line-height:80px;
		font-weight: 700;
		cursor:pointer;
	}
	.list-status {
		border-bottom: 3px solid #4284DC;
		color: #4284DC;
	}

	.grid-box-nav{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		gap: 17px;
		height: 100%;
	}
</style>